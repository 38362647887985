import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import quoteSvg from '../images/clients/quote.svg';
import messageTriangleSvg from '../images/clients/messageTriangle.svg';
import { useWindowSize } from '../utils/utils';

import styles from './Testimonials.module.scss';

const DESKTOP_BREAKPOINT = 1024;
const TABLET_BREAKPOINT = 700;
const N_TESTIMONIAL_ROWS = 4;

interface Testimonial {
  text: string;
  clientName: string;
  position: string;
  company: string;
}

interface TestimonialsProps {
  testimonials: Array<Testimonial>;
}

const Testimonials = ({ testimonials }: TestimonialsProps): React.ReactElement => {
  const windowSize = useWindowSize();
  const { t } = useTranslation();

  let nColumns = 1;
  if (windowSize && windowSize.width >= DESKTOP_BREAKPOINT) {
    nColumns = 3;
  } else if (windowSize && windowSize.width >= TABLET_BREAKPOINT) {
    nColumns = 2;
  }

  const initialTestimonialsShown = Math.min(nColumns * N_TESTIMONIAL_ROWS, testimonials.length);
  const incrementalTestimonialsShown = initialTestimonialsShown;

  const [maxTestimonialsShown, setMaxTestimonialsShown] = useState(initialTestimonialsShown);

  function loadMoreTestimonials() {
    setMaxTestimonialsShown(
      Math.min(maxTestimonialsShown + incrementalTestimonialsShown, testimonials.length),
    );
  }

  useEffect(() => {
    setMaxTestimonialsShown(initialTestimonialsShown);
  }, [nColumns]);

  const testimonialColumns: Array<Array<Testimonial>> = [];
  for (let i = 0; i < nColumns; i++) {
    testimonialColumns.push([]);
  }

  for (let i = 0; i < maxTestimonialsShown; i++) {
    testimonialColumns[i % nColumns].push(testimonials[i]);
  }

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        {testimonialColumns.map((testimonialColumn, i) => (
          <div className={styles.column} key={i}>
            {testimonialColumn.map((testimonial, j) => (
              <div className={styles.testimonial} key={j}>
                <div className={styles.quoteContainer}>
                  <img className={styles.quoteIcon} src={quoteSvg} alt="" />
                  <p className={styles.text}>{testimonial.text} </p>
                  <img src={messageTriangleSvg} alt="" className={styles.messageTriangle} />
                </div>
                <div className={styles.clientNameContainer}>
                  <span className={styles.name}>{testimonial.clientName}</span>
                  <div className={styles.job}>
                    <span className={styles.position}>{testimonial.position}</span>
                    {/* <span> | </span> */}
                    <span className={styles.company}>{testimonial.company}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      {testimonials.length > maxTestimonialsShown && (
        <button
          id="form-btn"
          className={styles.button + ' btn btn-color'}
          onClick={loadMoreTestimonials}
        >
          {t('testimonials.load_more_button', 'Ver Mais')}
        </button>
      )}
    </div>
  );
};

export default Testimonials;
