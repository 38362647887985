import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import withI18n from '../utils/hocs';
import { useScript } from '../utils/utils';
import FirstRenderContext from '../components/FirstRenderContext';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Intro from '../components/Intro';

import ogImage from '../images/fb-image.png';
import ogImageEn from '../images/fb-image_en.png';
import LocalizedLink from '../components/LocalizedLink';

import velhotesImage from '../images/clients/velhotes.png';
import homingImage from '../images/clients/homing.png';
import axiansImage from '../images/clients/axians.png';
import cafeImperioImage from '../images/clients/cafe-imperio.png';
import ceetrusImage from '../images/clients/ceetrus.png';
import consultanImage from '../images/clients/consultan.png';
import eLeclercImage from '../images/clients/e-leclerc.png';
import wineclickImage from '../images/clients/wineclick.png';
import cluttonsImage from '../images/clients/cluttons.png';
import ascenzaImage from '../images/clients/ascenza.png';
import liveLoveRideImage from '../images/clients/live-love-ride.png';
import fortyTwoLisboaImage from '../images/clients/42-lisboa.png';
import mediaCapitalImage from '../images/clients/media-capital.png';
import Testimonials from '../components/Testimonials';

declare const google: {
  maps: {
    Map: {
      new (
        el: HTMLElement,
        options: {
          zoom: number;
          center: { lat: number; lng: number };
        },
      ): unknown;
    };
    Marker: {
      new (options: { position: { lat: number; lng: number }; map: unknown }): unknown;
    };
  };
};
const IndexPage = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const isFirstRender = useContext(FirstRenderContext);

  const googleMapsScriptStatus = useScript(
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyB6EzKAlQNqSnLSQbDVKxq0CKSsWBCJCFI',
  );

  useEffect(() => {
    const mapEl = document.getElementById('map');
    if (googleMapsScriptStatus === 'ready' && mapEl) {
      // Initialize and add the map
      // The location of Mosca Digital
      const moscaDigital = { lat: 38.7084719, lng: -9.1529458 };
      // The map, centered at Mosca Digital
      const map = new google.maps.Map(mapEl, {
        zoom: 16,
        center: moscaDigital,
      });
      // The marker, positioned at Mosca Digital
      new google.maps.Marker({
        position: moscaDigital,
        map: map,
      });
    }
  }, [googleMapsScriptStatus]);
  return (
    <Layout>
      <SEO
        title={t('SEO.homepage.title', 'Mosca Digital - O digital criativo')}
        description={t('SEO.homepage.description', 'O digital não tem de ser complicado')}
        url={'https://moscadigital.pt' + (i18n.language === 'en' ? '/en' : '')}
        imageUrl={i18n.language === 'en' ? ogImageEn : ogImage}
      />
      <div id="main" className={isFirstRender ? 'hidden' : ''}>
        <div id="wrapper">
          {/* HERO */}
          <section id="home-hero" className="dark-bg">
            <div id="particle-canvas" />
            <div
              className="logo"
              style={{
                backgroundImage:
                  'url("/images/logo-main' + (i18n.language === 'en' ? '_en' : '') + '.png")',
              }}
            />
            <div className="scroll-icon">
              <p className="scroll-text">{t('homepage_hero.scroll', 'Curioso?')}</p>
              <a href="#quem-somos" data-scroll="true">
                <div className="mouse" />
              </a>
              <div className="end-top" />
            </div>
          </section>
          {/* QUEM SOMOS */}
          <section id="quem-somos" className="b-0 section-skewed">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-10">
                  <div className="title">
                    <h2>{t('who_we_are.title', 'O Digital não é nada sem a Criatividade')}</h2>
                    <p className="fw-300">
                      {t(
                        'who_we_are.description',
                        'Da próxima vez que alguém lhe disser que o digital é o futuro, dê-lhe um pontapé naquele sítio. O digital já não é o futuro, é o passado. O mundo está digital, mas o consumidor ainda é analógico, continua a gostar de storytelling mas em multiplataformas e com conteúdos que lhes sejam relevantes. A Mosca Digital é uma agência de nativos digitais, mas o digital já não nos impressiona. O que nos impressiona são as fantásticas possibilidades que esse mundo permite. O digital é só um meio, o que interessa são as ideias e claro está, a capacidade técnica de as executar. Por isso, se acha que o digital é o futuro, podemos recomendar-lhe várias outras agências digitais. Mas se quer fazer a diferença nesse fantástico mundo novo, venha falar com a Mosca Digital, a agência do digital criativo.',
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* QUE FAZEMOS */}
          <section id="que-fazemos" className="dark-bg">
            <div className="container">
              <div className="title">
                <h2>{t('what_we_do.title', 'O Que Fazemos')}</h2>
                <p className="fw-300">
                  <span
                    className="typed-words fw-400"
                    data-strings={t(
                      'what_we_do.strings',
                      '["Desenhamos soluções","Produzimos conteúdo","Criamos estratégias","Contamos histórias","Damos suporte"]',
                    )}
                    data-custom-color="#73aeef"
                  />
                  {t('what_we_do.final_sentence', ', sempre com foco na criatividade.')}
                </p>
              </div>
              <div className="section-content pt-25 pb-25">
                <div className="que-fazemos--row">
                  <div className="que-fazemos--box">
                    <div className="icon-box" data-animation="fadeInRight">
                      <div
                        className="animated-icon white"
                        data-icon="/images/icons/paintbrush.svg"
                      />
                      <div className="ib-content">
                        <h4 className="upper">{t('what_we_do.service.design.title', 'Design')}</h4>
                        <p>
                          {t(
                            'what_we_do.service.design.description',
                            'Desenhamos soluções atrativas e fáceis de utilizar.',
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="que-fazemos--box">
                    <div className="icon-box" data-animation="fadeInRight" data-delay={300}>
                      <div className="animated-icon white" data-icon="/images/icons/hammer.svg" />
                      <div className="ib-content">
                        <h4 className="upper">
                          {t('what_we_do.service.development.title', 'Desenvolvimento')}
                        </h4>
                        <p>
                          {t(
                            'what_we_do.service.development.description',
                            'Implementamos websites e apps com base nas tecnologias mais recentes.',
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="que-fazemos--box">
                    <div className="icon-box" data-animation="fadeInRight" data-delay={600}>
                      <div
                        className="animated-icon white"
                        data-icon="/images/icons/megaphone.svg"
                      />
                      <div className="ib-content">
                        <h4 className="upper">
                          {t('what_we_do.service.marketing.title', 'Marketing')}
                        </h4>
                        <p>
                          {t(
                            'what_we_do.service.marketing.description',
                            'Criamos estratégias para gerar mais tráfego, mais contactos e mais vendas.',
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="que-fazemos--box">
                    <div className="icon-box" data-animation="fadeInRight" data-delay={900}>
                      <div
                        className="animated-icon white"
                        data-icon="/images/icons/share-simple.svg"
                      />
                      <div className="ib-content">
                        <h4 className="upper">
                          {t('what_we_do.service.socialNetwork.title', 'Redes Sociais')}
                        </h4>
                        <p>
                          {t(
                            'what_we_do.service.socialNetwork.description',
                            ' Gerimos a comunicação em qualquer rede social e criamos conteúdos otimizados para as mesmas.',
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="que-fazemos--box">
                    <div className="icon-box" data-animation="fadeInRight" data-delay={1200}>
                      <div className="animated-icon white" data-icon="/images/icons/video.svg" />
                      <div className="ib-content">
                        <h4 className="upper">{t('what_we_do.service.video.title', 'Video')}</h4>
                        <p>
                          {t(
                            'what_we_do.service.video.description',
                            'Produzimos vídeos otimizados para os canais digitais.',
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="que-fazemos--box">
                    <div className="icon-box" data-animation="fadeInRight" data-delay={1500}>
                      <div className="animated-icon white" data-icon="/images/icons/record.svg" />
                      <div className="ib-content">
                        <h4 className="upper">{t('what_we_do.service.raves.title', 'Raves')}</h4>
                        <p>
                          {t(
                            'what_we_do.service.raves.description',
                            ' Fazemos festas de arromba todas as sextas a partir das 3 da manhã (entrada limitada a clientes).',
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* CLIENTES */}
          <section id="nossos-clientes" className="pt-100 pb-100">
            <div className="container">
              <div
                className="title"
                style={i18n.language === 'en' ? { textAlign: 'center' } : undefined}
              >
                <h2>{t('our_clients.title', 'Alguns dos nossos clientes')}</h2>
              </div>
              {i18n.language === 'pt' && (
                <Testimonials
                  testimonials={[
                    {
                      text:
                        'A minha parte favorita em trabalhar com a Mosca Digital é a capacidade de encarar os desafios, dando muito enfoque à avaliação do enquadramento, propondo sempre soluções pragmáticas e totalmente orientadas para os resultados. Escolheria-os para um novo trabalho porque sempre foram assertivos na abordagem que tiveram e porque o seu ADN é de verdadeiro parceiro e não de mero fornecedor.',
                      clientName: 'Manuel Almeida',
                      position: 'Diretor de Marketing',
                      company: 'Media Capital',
                    },
                    {
                      text:
                        'Escolher como parceira a Mosca Digital é saber que os nossos projetos estão bem entregues. Numa fase em que a presença digital assume um peso tão grande para a nossa empresa, a Mosca Digital ajudou-nos a concretizar um projeto sólido e capaz de responder não só às necessidades da nossa equipa – espalhada pelo mundo, como também às necessidades do mercado. Trabalhar com a Mosca Digital permite-nos saber que as nossas ideias são concretizáveis e os nossos projetos são acompanhados por uma equipa dedicada e esforçada pelo sucesso da nossa empresa.',
                      clientName: 'Madalena Cabrita',
                      position: 'Branding Technician',
                      company: 'ASCENZA Central Marketing',
                    },
                    {
                      text:
                        'Resolutivos, simpáticos e eficientes. Falam a nossa linguagem. Não hesitaria em trabalhar novamente com eles e recomendo vivamente.',
                      clientName: 'Mercedes Arribas',
                      position: 'Responsável Comercial',
                      company: 'Clever Real',
                    },
                    {
                      text:
                        'Desde o lançamento da 42 Lisboa que a Mosca Digital faz parte da nossa equipa. Não presta serviços. Não "agencia". Faz efetivamente parte da equipa. Prova disso é o compromisso e dedicação  com que regularmente se excede na criatividade e qualidade das propostas que apresenta para tornar a 42 mais forte, que vai muito além do "prestador de serviços".',
                      clientName: 'Mafalda Guedes',
                      position: 'Head of Studies & Communication',
                      company: '42Lisboa',
                    },
                    {
                      text:
                        'Eficientes, colaborativos, dinâmicos e focados na solução. As soluções tecnológicas que desenvolveram para o nosso negócio foram óptimas, o processo foi simples e adicionou muito valor ao nosso negócio. Não só recomendaria como tenho a certeza que voltarei a trabalhar com a Mosca Digital no futuro.',
                      clientName: 'Pedro Rocha',
                      position: 'CEO',
                      company: 'Live Love Ride',
                    },
                    {
                      text:
                        'Numa altura em que o meu projeto passava por uma fase em que tinha a certeza de estar no caminho certo, mas os resultados no online não eram os esperados, contactei a Mosca Digital. Após a primeira reunião com o José Brizida, fiquei com a certeza que tinha encontrado o parceiro certo para a Wineclick. O José identificou-se com a visão que eu tinha para a marca e com a direção que tinha planeado seguir. Identificamo-nos na forma de trabalhar e de pensar e tem sido uma aprendizagem e uma mais-valia, juntar a sinergia da Wineclick com o mundo digital que a Mosca domina. Após 2 anos de parceria, conseguimos criar uma excelente relação, que se expressa nos resultados que temos vindo a conseguir e cada vez mais com a certeza que a Mosca Digital tem sido essencial para a nossa afirmação e crescimento enquanto marca diferenciadora no mundo dos vinhos.',
                      clientName: 'Rui Costa',
                      position: 'Store Manager',
                      company: 'Wineclick',
                    },

                    {
                      text:
                        'São uma equipa talentosa e sempre pronta a ajudar os clientes nos seus desafios. Destaco o contacto próximo que têm com o cliente. Sinto que fazem parte da equipa desde o primeiro dia!',
                      clientName: 'André Teixeira',
                      position: 'Digital Marketing Strategist',
                      company: 'Media Capital',
                    },
                  ]}
                ></Testimonials>
              )}
              <div className="row">
                <div
                  className="client-carousel carousel"
                  data-slick='{"slidesToShow": 4, "dots": false}'
                >
                  <div className="carousel-item">
                    <figure>
                      <img src={velhotesImage} alt="Velhotes" />
                    </figure>
                  </div>
                  <div className="carousel-item">
                    <figure>
                      <img src={homingImage} alt="Homing" />
                    </figure>
                  </div>
                  <div className="carousel-item">
                    <figure>
                      <img src={ceetrusImage} alt="Ceetrus" />
                    </figure>
                  </div>
                  <div className="carousel-item">
                    <figure>
                      <img src={ascenzaImage} alt="ASCENZA" />
                    </figure>
                  </div>
                  <div className="carousel-item">
                    <figure>
                      <img src={mediaCapitalImage} alt="Media Capital" />
                    </figure>
                  </div>
                  <div className="carousel-item">
                    <figure>
                      <img src={liveLoveRideImage} alt="LiveLoveRide" />
                    </figure>
                  </div>
                  <div className="carousel-item">
                    <figure>
                      <img src={fortyTwoLisboaImage} alt="42 Lisboa" />
                    </figure>
                  </div>
                  <div className="carousel-item">
                    <figure>
                      <img src={axiansImage} alt="Axians" />
                    </figure>
                  </div>
                  <div className="carousel-item">
                    <figure>
                      <img src={cafeImperioImage} alt="Café Império" />
                    </figure>
                  </div>
                  <div className="carousel-item">
                    <figure>
                      <img src={consultanImage} alt="Consultan" />
                    </figure>
                  </div>
                  <div className="carousel-item">
                    <figure>
                      <img src={eLeclercImage} alt="E-Leclerc" />
                    </figure>
                  </div>
                  <div className="carousel-item">
                    <figure>
                      <img src={wineclickImage} alt="Wineclick" />
                    </figure>
                  </div>
                  <div className="carousel-item">
                    <figure>
                      <img src={cluttonsImage} alt="Cluttons" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* ORÇAMENTO IMEDIATO */}
          {i18n.language === 'pt' && (
            <section id="budget-section" className="parallax-section">
              <div className="row-parallax-bg">
                <div className="parallax-wrapper">
                  <div className="parallax-bg">
                    <img data-src="/images/instant-bugdet-bg.jpg" alt="Budget Background" />
                  </div>
                  <div
                    className="parallax-overlay gradient-overlay"
                    data-gradients="#002f93,#184fb3"
                  />
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-10 col-md-offset-1">
                    <div className="title text-center">
                      <h2>{t('budget.title', 'Orçamento imediato')}</h2>
                      <p className="fw-300">
                        {t(
                          'budget.description',
                          'Veja aqui quanto pode custar a sua ideia para uma app ou um site',
                        )}
                      </p>
                    </div>
                    <div className="buttons-wrapper">
                      <a
                        href="https://quantocustaumaapp.moscadigital.pt"
                        className="btn white-btn-lg"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('budget.how_much_label.app', 'Quanto custa uma app?')}
                      </a>
                      <a
                        href="https://quantocustaumsite.moscadigital.pt"
                        className="btn white-btn-lg"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('budget.how_much_label.site', 'Quanto custa um site?')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {/* O NOSSO BLOG */}
          {i18n.language === 'en' && (
            <section id="blog-section" className="parallax-section">
              <div className="row-parallax-bg">
                <div className="parallax-wrapper">
                  <div className="parallax-bg">
                    <img data-src="/images/bg-image-3.jpg" alt="Budget Background" />
                  </div>
                  <div
                    className="parallax-overlay gradient-overlay"
                    data-gradients="#002f93,#184fb3"
                  />
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-10 col-md-offset-1">
                    <div className="title text-center">
                      <h2>{t('blog_section.title', 'O Nosso Blog')}</h2>
                      <p className="fw-300">
                        {t(
                          'blog_section.description',
                          'Veja aqui os últimos artigos da Mosca Digital',
                        )}
                      </p>
                    </div>
                    <div className="buttons-wrapper">
                      <LocalizedLink
                        to="/blog"
                        className="btn white-btn-lg"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('blog_section.button_text', 'Ver Blog')}
                      </LocalizedLink>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {/* CONTACT FORM */}
          <section id="contact-us" className="last-section">
            <div className="container">
              <div className="title text-center">
                <h2>{t('contact_us.title', 'Entre em contacto connosco')}</h2>
                <h4>{t('contact_us.description', 'Vamos fazer história juntos')} </h4>
              </div>
              <div className="row">
                <div
                  id="contact_form_left"
                  className="col-md-6 col-md-offset-0 col-sm-10 col-sm-offset-1 col-xs-10 col-xs-offset-1"
                >
                  <div className="contact_form">
                    <form
                      id="contact-form"
                      name="contact"
                      data-netlify="true"
                      data-netlify-honeypot="hidden-field"
                    >
                      <input
                        className="hidden"
                        name="hidden-field"
                        placeholder="language"
                        autoComplete="language"
                        type="text"
                      />
                      <div className="form-group animated fadeInRight" data-animation="fadeInRight">
                        <label>{t('contact_form.label_name', 'Nome')}</label>
                        <input
                          id="name"
                          className="form-control"
                          autoComplete="given-name"
                          type="text"
                          name="name"
                          data-required=""
                        />
                        <p id="name-error" className="text-danger"></p>
                      </div>
                      <div className="form-group animated fadeInRight" data-animation="fadeInRight">
                        <label>{t('contact_form.label_email', 'Email')}</label>
                        <input
                          id="email"
                          className="form-control"
                          type="email"
                          autoComplete="email"
                          name="email"
                          data-required=""
                        />
                        <p id="email-error" className="text-danger"></p>
                      </div>
                      <div className="form-group animated fadeInRight" data-animation="fadeInRight">
                        <label>{t('contact_form.label_phone', 'Número de telemóvel')}</label>
                        <input
                          id="phone-number"
                          className="form-control"
                          autoComplete="tel-national"
                          type="text"
                          name="phone"
                        />
                        <p id="phone-number-error" className="text-danger"></p>
                      </div>
                      <div className="form-group animated fadeInRight" data-animation="fadeInRight">
                        <label>{t('contact_form.label_message', 'Mensagem')}</label>
                        <textarea
                          id="form-message"
                          className="form-control"
                          name="message"
                          data-required=""
                        ></textarea>
                        <p id="form-message-error" className="text-danger"></p>
                      </div>
                      <div
                        className="form-group mt-25 animated fadeInRight"
                        data-animation="fadeInRight"
                      >
                        <button id="form-btn" className="btn btn-color" type="submit">
                          {t('contact_form.submit_button', 'Enviar mensagem')}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  id="contact_form_right"
                  className="col-md-6 col-md-offset-0 col-sm-10 col-sm-offset-1 col-xs-10 col-xs-offset-1"
                >
                  <div className="contact_info">
                    <div className="email_sec">
                      {/* <div className="row">
                        <div className="col-md-2 col-sm-1 col-xs-1 mt_icon">
                          <i className="hc-call" />
                        </div>
                        <div className="col-md-10 col-sm-10 col-xs-10 mt_info">
                          <h5>{t('contact_us.phone_label', 'Ligue-nos')}</h5>
                          <span>
                            <a id="phone_number_link" href="tel:+351968632129">
                              968 632 129
                            </a>
                          </span>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-md-2 col-sm-1 col-xs-1 mt_icon">
                          <i className="hc-mail-open" />
                        </div>
                        <div className="col-md-10 col-sm-10 col-xs-10 mt_info">
                          <h5>{t('contact_us.mail_label', 'Envie-nos um email')}</h5>
                          <span>
                            <a href="mailto:geral@moscadigital.pt">geral@moscadigital.pt</a>
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-2 col-sm-1 col-xs-1 mt_icon">
                          <i className="hc-pin" />
                        </div>
                        <div className="col-md-10 col-sm-10 col-xs-10 mt_info">
                          <h5>{t('contact_us.address_label', 'Venha ter connosco')}</h5>
                          <a
                            href="https://www.google.com/maps/place/Mosca+Digital/@38.7084761,-9.1529458,17z/data=!3m1!4b1!4m5!3m4!1s0xd19352094351e97:0xd374db78a6284b16!8m2!3d38.7084719!4d-9.1529458"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Avenida Dom Carlos I 60, 4º Andar, <br />
                            1200-649, Lisboa, Portugal
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="map" className="map_info" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Intro />
    </Layout>
  );
};
export default withI18n(IndexPage);
