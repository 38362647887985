import React from 'react';
import { useTranslation } from 'react-i18next';

import nerdImage from '../images/intro/nerd.gif';
import pointingHandImage from '../images/intro/pointing-hand.png';
import bigornaImage from '../images/intro/bigorna-sem-fumo.png';
import buttonSimplifyImage from '../images/intro/botao.png';
import buttonSimplifyEnImage from '../images/intro/botao_en.png';

import './Intro.scss';
import styles from './Intro.module.scss';

const Intro = (): React.ReactElement => {
  const { t, i18n } = useTranslation();

  return (
    <div id="intro-max">
      <div id="intro" className="invisible">
        <div id="images-wrapper">
          <div id="talking-head-wrapper">
            <img id="nerd" src={nerdImage} alt="" />
            <div id="nerd-text-wrapper">
              <p id="intro-text1"></p>
              <p id="intro-text2"></p>
              <p id="intro-text3"></p>
            </div>
          </div>
          <div id="button-section">
            <div id="translateButton">
              <img
                className="button"
                src={i18n.language === 'en' ? buttonSimplifyEnImage : buttonSimplifyImage}
                alt=""
              />
            </div>
            <img id="pointing-hand" src={pointingHandImage} alt=" " />
          </div>
          <img id="bigorna" src={bigornaImage} alt="" />
        </div>
        <div className={styles.languageSwitcher}>
          <div className={styles.languageSwitcherInner}>
            <span
              className={i18n.language === 'pt' ? styles.selectedLang : ''}
              onClick={() => {
                const path = window.location.pathname;
                if (path.startsWith('/en')) {
                  window.location.href = path.substring(3);
                }
              }}
            >
              PT
            </span>
            &nbsp;|&nbsp;
            <span
              className={i18n.language === 'en' ? styles.selectedLang : ''}
              onClick={() => {
                const path = window.location.pathname;
                if (!path.startsWith('/en')) {
                  window.location.href = '/en' + path;
                }
              }}
            >
              EN
            </span>
          </div>
        </div>
      </div>

      <div id="interstitial" className="invisible">
        <p>{t('interstitial.slogan', 'O digital não tem de ser complicado')}</p>
      </div>
    </div>
  );
};
export default Intro;
